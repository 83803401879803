<template>
  <div class="invoice_test_box">
    <headSearch ref="headSearch" :search-out="headSearch" />
    <input v-model="testData" type="text">
    <div class="bottom" @click="btnTo()">保存</div>
  </div>

</template>

<script>
import headSearch from '@/component/head/search'

export default {
  name: 'InvoiceTest',
  components: {
    headSearch
  },
  data() {
    return {
      // a == 1 ? 10 :(a ==2 ? 20 :'30')
      // this.$store.state.email),
      // testData:this.$route.query.type==2 ? this.$store.state.number : this.$store.state.title,
      testData: this.$route.query.type === 2 ? this.$store.state.number : (this.$route.query.type === 1 ? this.$store.state.title : this.$store.state.email),
      testData1: this.$store.state.email,
      headSearch: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: this.$route.query.type === 2 ? '收纳人识别号' : this.$route.query.type === 1 ? '发票抬头' : '选择邮箱',
        callbackGo: this.callbackGo // 回调
      }
    }
  },
  mounted() {

  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    btnTo() {
      if (this.$route.query.type === 1) {
        this.$store.commit('isTitle', this.testData)
      } else if (this.$route.query.type === 2) {
        this.$store.commit('isNumber', this.testData)
      } else {
        this.$store.commit('isEmail', this.testData)
      }
      if (this.$route.query.type === 1 || this.$route.query.type === 2) {
        this.$router.push({
          path: '/addInvoice',
          query: {}
        })
      } else {
        this.$router.push({
          path: '/invoice',
          query: {}
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.invoice_test_box {
  background: #f6f6f6;

  input {
    width: 100%;
    height: 42px;
    margin: 0 auto;
    background: #fff;
    padding-left: 12px;
    box-sizing: border-box;
    color: #666;
  }

  .bottom {
    margin: 12px;
    height: 44px;
    line-height: 44px;
    color: #fff;
    background: orange;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
  }
}

</style>
